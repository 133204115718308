import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import Photo from '../components/Photo';
import "../styles/common_style.scss";
import { navigate } from "gatsby";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { Link, I18nextContext } from "gatsby-plugin-react-i18next";
import SuccessAlert from '../components/common/SuccessAlert';
import ErrorAlert from '../components/common/ErrorAlert';
import { updateTenantAdminPassword } from "../services/trial-tenant-identity-service";
import { addNewRegisteredUser } from "../services/tenant-identity-service";
import { Auth, Amplify } from 'aws-amplify';
import awsPoolConfiguration from '../aws-exports';
import { getIdTokenFromCookie, deleteCookie } from '../services/cookie-storage-service';
import { Helmet } from "react-helmet";
import * as uuid from "uuid";

const initialFormValue = {
    password: "",
    confirmpassword: ""
}

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;

function Trialcredentialpage() {
    const { language } = React.useContext(I18nextContext);
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/trialcredentialpage/"
            : GATSBY_LANDING_PAGE_URL + `/${language}/trialcredentialpage/`;

    const canonicalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
        language === "en"
            ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
            : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
    const hrefUrl = buildHrefUrl();


    const [alert_type, setAlertType] = useState("");
    const [alert_message, setAlertMessage] = useState('');
    const [formValues, setformValues] = useState(initialFormValue);
    const [passwordMisMatchErr, setPasswordMisMatchErr] = useState("");
    const [isRegisterationCompleted, setRegisterationStatus] = useState(false);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userinfo'));

        if (userData === null || userData === undefined || userData === '') {
            let currentLanguageCode = `${language}`;

            if (currentLanguageCode === 'en') {
                navigate('/');
            }
            else {
                navigate(`/${currentLanguageCode}/`);
            }
        }

        let idTokenFromCookie = getIdTokenFromCookie();

        if (idTokenFromCookie !== "") {
            deleteCookie()
                .then((response) => {
                })
                .catch(err => {
                })
        }
    }, [language]);

    const handleChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }

    const validate = () => {
        let passwordMisMatchErr = '';

        if (formValues.password !== formValues.confirmpassword) {
            passwordMisMatchErr = "Password don't match.";
        }

        if (passwordMisMatchErr) {
            setPasswordMisMatchErr(passwordMisMatchErr);
            return false;
        }

        return true;
    }

    const clearAlertType = () => {
        setAlertType("");
        setAlertMessage("");
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        clearAlertType();
        let isValid = validate();
        let idTokenFromCookie = getIdTokenFromCookie();

        if (idTokenFromCookie !== "") {
            await deleteCookie();
        }

        if (isValid) {
            setRegisterationStatus(true);
            setPasswordMisMatchErr("");
            let userData = JSON.parse(localStorage.getItem('userinfo'));

            let inputData = {
                "username": userData.email,
                "newpassword": formValues.password
            }

            updateTenantAdminPassword(inputData)
                .then(async (response) => {
                    let tenantResult = response.data;
                    let currentLanguageCode = `${language}`;

                    let registeredUserParam = {
                        "registeredUserGuid": uuid.v4(),
                        "email": userData.email,
                        "is_Trial": true,
                        "createdDate": new Date().toISOString()
                    }

                    await addNewRegisteredUser(registeredUserParam);

                    awsPoolConfiguration.aws_project_region = tenantResult.region;
                    awsPoolConfiguration.aws_cognito_identity_pool_id = tenantResult.identityPoolId;
                    awsPoolConfiguration.aws_cognito_region = tenantResult.region;
                    awsPoolConfiguration.aws_user_pools_id = tenantResult.userPoolId;
                    awsPoolConfiguration.aws_user_pools_web_client_id = tenantResult.clientId;

                    Amplify.configure(awsPoolConfiguration);

                    await Auth.signIn(inputData.username, inputData.newpassword)
                        .then((user) => {
                            if (currentLanguageCode === 'en') {
                                navigate("/location", {
                                    state: {
                                        'isRegisterationCompleted': isRegisterationCompleted
                                    }
                                });
                            }
                            else {
                                navigate(`/${currentLanguageCode}/location`, {
                                    state: {
                                        'isRegisterationCompleted': isRegisterationCompleted
                                    }
                                });
                            }
                        }).catch(error => {
                            let errorObj = JSON.parse(JSON.stringify(error));
                            let errMessage = errorObj.message;
                            setAlertType("ERROR")
                            setAlertMessage(errMessage);
                        });
                }).catch(err => {
                    let errMessage = err.response.data.errorMessage;
                    setAlertType("ERROR")
                    setAlertMessage(errMessage);
                });
        }

    }
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Credentials</title>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />
                <meta name="description" content="" />
                <meta name="Keywords" content="web development, companypage" data-react-helmet="true" />

            </Helmet>
            <main className="purchase-page height_100_percent_page" lang={language}>
                <div className="purchase-page-div height_100_percent_page_div" lang={language}>
                    <div className="container">
                        {
                            alert_type === 'SUCCESS' ? (<SuccessAlert message={alert_message} />) : null
                        }

                        {
                            alert_type === 'ERROR' ? (<ErrorAlert message={alert_message} />) : null
                        }

                        {
                            isRegisterationCompleted ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="app_setting_div">
                                            <div className="setting_content_div text-center mb-3">
                                                <h3>Your application is setting up. Please wait...</h3>
                                            </div>
                                            <div className="setting_image_div text-center">
                                                <Photo src="logipulse-loader.gif" className="" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row purchase-total" lang={language}>
                                    <div className="col-md-6">
                                        <div className="contents ipadcredential">
                                            <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                            <p><Trans>Create a SaaS account to have a single helping aid to handle everything from pickup to delivery. Supervise your logistics operations from one platform and expand it out around the world!</Trans></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="contents">
                                            {/* <!-- multistep form --> */}
                                            <div className="purchase_heading_section">
                                                <div className="profile"><b><Trans>Credentials</Trans></b></div>
                                                <div className="purchase_progress">
                                                    <ul id="progressbar_purchase">
                                                        <li></li>
                                                        <li className="active"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <Form onSubmit={submitHandler} autoComplete="off">

                                                <Form.Group controlId="formPassword">
                                                    <Form.Label><Trans>Choose Password</Trans></Form.Label>
                                                    <Form.Control type="password"
                                                        name="password"
                                                        placeholder="********"
                                                        autoFocus
                                                        value={formValues.password}
                                                        onChange={handleChange} required
                                                    />

                                                </Form.Group>

                                                <Form.Group controlId="formConformPassword">
                                                    <Form.Label><Trans>Confirm Password</Trans></Form.Label>
                                                    <Form.Control type="password"
                                                        name="confirmpassword"
                                                        placeholder="********"
                                                        value={formValues.confirmpassword}
                                                        onChange={handleChange} required
                                                    />
                                                </Form.Group>

                                                {passwordMisMatchErr ? (
                                                    <div style={{ color: "red", fontSize: "12px" }}>{passwordMisMatchErr}</div>
                                                ) : null}

                                                <div className="password_policy">Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols</div>

                                                <Form.Group className="next_button_align" controlId="formBasicCheckbox">
                                                    <button type="submit" className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative margin_left_auto" ><Trans>NEXT</Trans></button>
                                                </Form.Group>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            )
                        }


                    </div>
                </div>

                <footer className="purchase-footer">
                    <div className="purchase-footer-bottom">
                        <div className="container">

                            <div className="row align-content-center">
                                <div className="col-md-4 power">
                                    <div><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="" className="powered_logo" />
                                </div>
                                <div className="col-md-4 copyright">
                                    <div>
                                        <Trans>Copyright 2021 Stellosys</Trans><br /><Trans> All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>
            </main>
        </div>
    )
}

export default Trialcredentialpage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`